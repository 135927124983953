import { deflate } from "zlib";
import styles from "./Flex.module.css";

interface FlexProps{
    children?:any;
    direction?:"column" | "row"
    gap?:number;
}

function Flex(props:FlexProps){
    const gap = props.gap ? props.gap + "px" : undefined;
    return (
        <div className={styles.body} style={{flexDirection:props.direction, gap:gap}}>
            {props.children}
        </div>
    )
}

export default Flex;