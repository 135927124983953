import styles from "./PageHeader.module.css";

interface PageHeaderProps{
    children:any;
    services:Map<string, any>;

    title:string;
}

function PageHeader(props:PageHeaderProps){
     return(
        <div className={styles.body}>
            <span className={styles.title}>{props.title}</span>
            <div className={styles.tools}>
                {props.children}
            </div>
        </div>
     )
}

export default PageHeader;