import { binding } from "../../../core/binding/binding";

interface IFProps{
    predicate:string;
    children:any;
    services:Map<string, any>;
}

function IF(props:IFProps){
    var predicate = binding(props.services, props.predicate);
    if(predicate){
        return <>{props.children}</>;
    }
    return <></>;
}

export default IF;