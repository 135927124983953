import { createElement } from "react";
import NodeElement from "../data/Node";
import Content from "../../component/core/content/ContentHandler";
import Button from "../../component/core/button/ButtonHandler";
import Flex from "../../component/layers/flex/Flex";
import Label from "../../component/core/label/Label";
import Input from "../../component/core/input/input";
import { observer } from "mobx-react-lite";
import Grid from "../../component/layers/grid/Grid";
import DataGrid from "../../component/core/dataGrid/DataGrid";
import { CustomComponent } from "../../App";
import IF from "../../component/utils/if/IF";
import FOR from "../../component/utils/for/FOR";
import PageHeader from "../../component/page/PageHeader";
import Notes from "../../component/core/notes/Notes";

interface IComponentLibary{
    [key:string] : React.FunctionComponent<any>
}

const ComponentLibary : IComponentLibary = {
    ["Content"] : Content,
    ["Button"] : Button,
    ["Label"] : Label,
    ["Flex"] : Flex,
    ["Render"] : Flex,
    ["Input"] : Input,
    ["Grid"] : Grid,
    ["DataGrid"] : DataGrid,
    ["IF"] : IF,
    ["FOR"] : FOR,
    ["PageHeader"] : PageHeader,
    ["Notes"] : Notes
}

function componentBuild(node:NodeElement, services:Map<string, any>) : React.ReactNode{
    const hasComponent =  Reflect.has(ComponentLibary, node.type);
    if(!hasComponent){
        return createElement(observer(CustomComponent), {name:node.type, props:node.attribute});
    }
    return createElement(observer(ComponentLibary[node.type]), {...node.attribute, value:node.value, services:services}, node.children.map(n => componentBuild(n, services)));
}

export default componentBuild;