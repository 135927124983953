
interface GridProps{
    children?:any;
    gap?:string;
    columns?:string;
    rows?:string;
}

function Grid(props:GridProps){
    return(
        <div style={{gap:props.gap+"px", gridTemplateColumns:props.columns, gridTemplateRows:props.rows, display:"grid"}}>
            {props.children}
        </div>
    )
}

export default Grid;