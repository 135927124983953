import React, { useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import XmlToJson from './core/xmlParser/xmlParser';
import componentBuild from './core/pageBuilder/pageBuilder';
import locContainer, { ServiceType } from './core/loc/Loc';
import { Type } from 'typescript';
import NodeElement from './core/data/Node';
import { observer } from 'mobx-react-lite';
import HttpClient from './core/services/HttpClient';
import axios from 'axios';
import { log } from 'console';

class TestService{
  get(){
    alert("Это победа!");
    return "ЕЕЕЕе!";
  }
}

const customService2 = `
(
  class CustomService2{
    get(){
      return "Hello, world";
    }
  }
)
`;

const customService = `
(
  class CustomService{

    textService;

    constructor(text){
      this.textService = text;
    }

    get(){
      return this.textService.get();
    }
  }
)
`;

locContainer.register("text", TestService, ServiceType.Singleton);
locContainer.register("httpClient", HttpClient, ServiceType.Scoped);

function App() {
  const [isDesigner, setDesigner] = useState<boolean>(false);
  const [service, setService] = useState<string>(localStorage.getItem('service') ?? "");
  const [render, setRender] = useState<string>(localStorage.getItem('render') ?? "");

  const [result, setResult] = useState<string>("");

  function applay(){
    localStorage.setItem('render', render);
    localStorage.setItem('service', service);

    locContainer.register("test", eval(service), ServiceType.Scoped);
    setResult(render);
  }

  return (
    <div className="App">
      <div className='function'>
        <div className="toolpanel">
          <button onClick={() => setDesigner(false)}>Код</button>
          <button onClick={() => setDesigner(true)}>Дизайнер</button>
        </div>
        {!isDesigner ?
        <div className="toolpanel">
          <div>
            <div>Render</div>
            <textarea value={render} onChange={(elment) => setRender(elment.target.value)}/>
          </div>
          <div>
            <div>Service</div>
            <textarea value={service} onChange={(elment) => setService(elment.target.value)}/>
          </div>
          <button onClick={applay}>Применить</button>
        </div>
        :
        <Designer />
      }
      </div>
      {result !== '' && <CustomPage xml={result}/>}
    </div>
  );
}

function Designer(){
  return(
    <div>

    </div>
  )
}

export function CustomComponent(props:{name:string, props:any}){
  const [xml, setXml] = useState<string>("");

  useEffect(()=>{
    async function load() {
      setXml(await (await axios.get("https://test.oscreator.ru/reactor/" + props.name)).data.value);
    }

    load();
  },[])

  return <>{xml !== '' && <CustomPage xml={xml} props={props.props}/>}</>
}

const CustomPage = observer((props:{xml:string, props?:any}) => {
  const obj = XmlToJson(props.xml);
  const render = obj.children.find(o => o.type === "Render");
  const module = obj.children.find(o => o.type === "Module");
  const services = useMemo<Map<string,any>>(() => { 
    const services = new Map();
    if(module){
      for(let service of module.children){
        let obj = locContainer.get(service.attribute["name"]);
        if(Reflect.has(obj, "__init")){
          if(props.props) obj.__init(props.props);
        }
        services.set(service.attribute["name"], obj);
      }      
    }

    return services;
  },[module]);

  return(
    <>
      {componentBuild(render!, services)}
    </>
  )
});

export default App;
