import { ChangeEvent } from "react";
import { binding, bindingInXml } from "../../../core/binding/binding";

interface InputProps{
    text:string;
    change:string;
    services:Map<string, any>;
}

function Input(props:InputProps){

    function onChange(value:ChangeEvent<HTMLInputElement>){
        binding(props.services, props.change)(value.target.value);
    }

    return (
        <input value={binding(props.services, props.text)} onChange={onChange}/>
    )
}

export default Input;