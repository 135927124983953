import processTemplate from "./macros";

export function bindingInXml(services:Map<string, any>, bindingString?:string){
    return processTemplate(bindingString ?? "", (value) => binding(services, value.trim().replace("(bind)", "")));
}

export function binding(services:Map<string, any>, binding?:string){
    const bind = binding?.split(":");
    if(bind){
        switch(bind[0]){
            case "func":
                return bindFunction(services, bind);
            case "value":
                return bindValue(services, bind);
            case "exec":
                return bindFunction(services, bind).call();
        }
    }
}

function bindValue(services:Map<string, any>, binding?:string[]){
    if(binding){
        const service = services!.get(binding[1]);
        return Reflect.get(service, binding[2]);;
    }

    return binding;
}

function bindFunction(services:Map<string, any>, binding?:string[]){
    if(binding){
        const clickService = services!.get(binding[1]);
        const clickFunction = Reflect.get(clickService, binding[2]).bind(clickService);
        return clickFunction;
    }

    return undefined;
}