import { ChangeEvent, ChangeEventHandler } from "react";
import { binding, bindingInXml } from "../../../core/binding/binding";
import styles from "./Notes.module.css";

interface NotesProps{
    children:any;
    services:Map<string, any>;

    change?:string;
    text?:string;
}

function Notes(props:NotesProps){
    const func = binding(props.services, props.change);

    function onchange(event:ChangeEvent<HTMLTextAreaElement>){
        func(event.target.value);
    }

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                Заметки
            </div>
            <div className={styles.content}>
                <textarea value={bindingInXml(props.services, props.text)} onInput={onchange}></textarea>
            </div>
        </div>
    )
}

export default Notes;