import React from 'react';
import { binding } from '../../../core/binding/binding';
import styles from "./DataGrid.module.css";

interface DataGridProps {
  data: string; // Массив объектов с неизвестной структурой данных
  services:Map<string, any>;
}

function DataGrid(props: DataGridProps) {
  const data = binding(props.services, props.data) as any[];

  // Получаем список уникальных свойств из объектов данных
  const properties = Array.from(
    new Set(data.flatMap((item) => Object.keys(item)))
  );

  return (
    <table className={styles.dataGrid}>
      <thead>
        <tr>
          {/* Заголовки столбцов */}
          {properties.map((property) => (
            <th key={property}>{property}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Рендеринг данных */}
        {data.map((item, index) => (
          <tr key={index}>
            {properties.map((property) => (
              <td key={property}>{item[property]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DataGrid;