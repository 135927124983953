import { observer } from "mobx-react-lite";
import { bindingInXml, binding } from "../../../core/binding/binding";
import styles from "./ButtonHandler.module.css";

interface ButtonProps{
    children?:React.ReactNode[] | React.ReactNode
    value?:string;
    click?:string;
    services:Map<string, any>
}

function Button(props:ButtonProps){
    
    const value = bindingInXml(props.services, props.value);

    return(
        <button className={styles.button} onClick={binding(props.services, props.click)}>{value ?? props.children}</button>
    )
}

export default Button;