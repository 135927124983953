import { observer } from "mobx-react-lite";
import { bindingInXml } from "../../../core/binding/binding";

interface LabelProps{
    value:string;
    size?:number;
    services:Map<string, any>;
}
function Label(props:LabelProps){
    const size = props.size ? props.size+"px" : undefined;
    return (
        <span style={{fontSize:size}}>{bindingInXml(props.services, props.value)}</span>
    )
}

export default Label;