import styles from "./ContentHandler.module.css";

interface ContentProps{
    children?:React.ReactNode;
}

function Content(props:ContentProps){
    return(
        <div className={styles.body}>
            {props.children}
        </div>
    )
}

export default Content;