function processTemplate(text: string, action: (template: string) => string): string {
    const regex = /{{(.*?)}}/g;
    let result = text;
    let match;
  
    while ((match = regex.exec(text)) !== null) {
      const template = match[1];
      const replacement = action(template);
      result = result.replace(match[0], replacement);
    }
  
    return result;
  }

  export default processTemplate;