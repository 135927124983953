import axios from "axios";

class HttpClient{

    constructor(){
        
    }

    post(url:string, data:any){
        return axios.post(url, data);
    }

    get(url:string, data:any){
        return axios.get(url, data);
    }
}

export default HttpClient;