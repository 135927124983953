import { makeAutoObservable, observable } from "mobx";
import { Type} from "typescript";

export enum ServiceType{
    Singleton,
    Scoped,
}

class Service{
    type:ServiceType = ServiceType.Scoped;
    service:any;

    constructor(type:ServiceType, service:any){
        this.service = service;
        this.type = type;
    }
}

class Loc{
    private services : Map<string, Service> = new Map();
    private singeltonInstance : Map<string, any> = new Map();

    register(name:string, services: any, type:ServiceType){
        this.services.set(name, new Service(type, services));
    }

    get(name:string) : any{
        const info = this.services.get(name);
        const service = info?.service;
        if(service){
            if(info.type === ServiceType.Singleton){
                const instance = this.singeltonInstance.get(name);
                if(instance){
                    return instance;
                }
            }
            
            let args = this.getService(service.prototype);
            const obj = makeAutoObservable(new service(...args));
            
            if(info.type === ServiceType.Singleton) this.singeltonInstance.set(name, obj);
            
            return obj;
        }
        return null;
    } 

    private getService(type:any) : any[]{
        let serviceNames = type.constructor.toString().match(/\(([^)]+)\)/);
        
        try{
            serviceNames = serviceNames[1];
        }
        catch{
            return [];
        }
        
        let services = []
        if(serviceNames){
            for(let name of serviceNames.split(",")){
                services.push(this.get(name));
            }
        }

        return services;
    }
}

const locContainer = new Loc();

export default locContainer;